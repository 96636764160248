import { Flex, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { fetchValidator } from '~/api/store_api.ts';

import { useParams } from 'react-router-dom';
type ValidatorType = {
  validator: string;
  uptime: string;
  votingPower: string;
  participation: string;
  commitSignatures: number;
  moniker: string;
};

function ValidatorDetail() {
  const [loading, setLoading] = useState(false);
  const [validatorDetail, setValidatorDetail] = useState<ValidatorType>({
    validator: '',
    uptime: '',
    votingPower: '',
    participation: '',
    commitSignatures: 0,
    moniker: '',
  });
  let { address } = useParams();

  const getTransDetail = async () => {
    const response = await fetchValidator(address);
    setValidatorDetail(response);
    setLoading(false)
  };
  useEffect(() => {
    getTransDetail();
  }, [address]);

  useEffect(() => {
    setLoading(true)
  }, []);

  return (
    <Flex vertical gap="large">
      {loading ? (
        <>
          <div className="spin-wrapper">
            <Spin tip="Loading" size="large" />
          </div>
        </>
      ) : !validatorDetail?.moniker ? (
        <>Data not found</>
      ) : (
        <>
          <div className="info-container validator-info-container">
            <div className="info-wrapper moniker">
              <p>Moniker</p>
              <div className="info">
                <p>{validatorDetail.moniker}</p>
              </div>
            </div>
            <div className="info-wrapper address">
              <p>Address</p>
              <div className="info ">
                <p>{validatorDetail.validator}</p>
              </div>
            </div>
            <div className="info-wrapper">
              <p>Voting power</p>
              <div className="info">
                <p>{validatorDetail.votingPower}</p>
              </div>
            </div>
            <div className="info-wrapper">
              <p>Commit signatures</p>
              <div className="info">
                <p>{validatorDetail.commitSignatures}</p>
              </div>
            </div>
            <div className="info-wrapper">
              <p>Participation</p>
              <div className="info">
                <p>{validatorDetail.participation}</p>
              </div>
            </div>
            <div className="info-wrapper">
              <p>Up time</p>
              <div className="info">
                <p>{validatorDetail.uptime}</p>
              </div>
            </div>
          </div>
        </>
      )}
    </Flex>
  );
}

export default ValidatorDetail;

import { GetProp, Table, TableProps, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchBlocks } from '~/api/store_api.ts';
import config from '~/config';
import { format, formatDistanceToNow } from 'date-fns';
import useInterval from '~/hooks/useInterval';

type ColumnsType<T> = TableProps<T>['columns'];
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface DataType {
  height: string;
  hash: string;
  txs: string;
  proposer: string;
  time: string;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

function generateBlockDetailPath(template, height) {
  let path = template;
  path = path.replace(`:height`, height);
  return path;
}

function generateValidatorDetailPath(template, address) {
  let path = template;
  path = path.replace(`:address`, address);
  return path;
}

function Blocks() {
  const [originData, setOriginData] = useState<DataType[]>([]);
  const [loading, setLoading] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const columns: ColumnsType<DataType> = [
    {
      title: 'NO',
      dataIndex: 'no',
      key: 'no',
      render: (no) => <span>{(tableParams.pagination.current - 1) * tableParams.pagination.pageSize + no}</span>,
    },
    {
      title: 'BLOCK',
      dataIndex: 'height',
      key: 'height',
      render: (height) => <Link to={generateBlockDetailPath(config.routes.blockDetail, height)}>{height}</Link>,
    },
    {
      title: 'TXS',
      dataIndex: 'txs',
      key: 'txs',
    },
    {
      title: 'HASH',
      dataIndex: 'hash',
      key: 'hash',
    },
    {
      title: 'VALIDAROR',
      dataIndex: 'proposer',
      key: 'proposer',
      render: (proposer) => (
        <Link to={generateValidatorDetailPath(config.routes.validatorDetail, proposer)}>{proposer}</Link>
      ),
    },
    {
      title: 'TIME',
      dataIndex: 'time',
      key: 'time',
      render: (time) => (
        <Tooltip placement="bottom" title={format(time, 'yyyy-MM-dd hh:mm:ss')}>
          <p>{formatDistanceToNow(time || '', { addSuffix: true })}</p>
        </Tooltip>
      ),
    },
  ];

  const fetchData = async () => {
    try {
      const response = await fetchBlocks(tableParams.pagination.current, tableParams.pagination.pageSize);
      if (response && response.data) {
        const tempData = response.data.map((data) => {
          return {
            key: data.height,
            ...data,
          };
        });

        setOriginData(tempData);
        if(!isFilter){
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              total: response.total,
            },
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true)
      fetchData();
  }, [tableParams.pagination.current, tableParams.pagination.pageSize])

  useInterval(() => {
    if(tableParams.pagination.current === 1){
      fetchData();
    }
  }, 5000);

  const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter, extra) => {
    if(pagination.current !== 1){
      setIsFilter(true)
    } else {
      setIsFilter(false)
    }
    setTableParams({
      pagination,
    });
  };

  return (
    <>
      <h2>List blocks</h2>
      <Table
        dataSource={originData}
        columns={columns}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </>
  );
}

export default Blocks;

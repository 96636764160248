import { GetProp, Input, Table, TableProps } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchValidatorsWithDetails } from '~/api/store_api.ts';
import config from '~/config';
import { useDebounce } from '~/hooks';

type ColumnsType<T> = TableProps<T>['columns'];
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface DataType {
  validator: string;
  moniker: string;
  upTime: string;
  votingPower: string;
  commitSignatures: string;
  participation: string;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

const { Search } = Input;

function generatePath(template, address) {
  let path = template;
  path = path.replace(`:address`, address);
  return path;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'RANK',
    dataIndex: 'rank',
    key: 'rank',
  },
  {
    title: 'VALIDATOR',
    dataIndex: 'validator',
    key: 'validator',
    render: (address) => <Link to={generatePath(config.routes.validatorDetail, address)}>{address}</Link>,
  },
  {
    title: 'MONIKER',
    dataIndex: 'moniker',
    key: 'moniker',
  },
  {
    title: 'OPERATOR',
    dataIndex: 'operator',
    key: 'operator',
  },
  {
    title: 'VOTING POWER',
    dataIndex: 'votingPower',
    key: 'votingPower',
  },
  {
    title: 'PARTICIPATION',
    dataIndex: 'participation',
    key: 'participation',
  },
];

function Validator() {
  const [data, setData] = useState<DataType[]>([]);
  const [originData, setOriginData] = useState<DataType[]>([]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [searchValue, setSearchValue] = useState('');
  const debounced = useDebounce(searchValue, 500);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetchValidatorsWithDetails();
      if (response) {
        const tempData = response.map((data, index) => {
          return {
            key: index,
            ...data,
          };
        });
        setOriginData(tempData);
        setData(tempData);

        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: tempData.length,
          },
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter, extra) => {
    setTableParams({
      pagination,
    });
  };

  useEffect(() => {
    filterDataBySearchInput(debounced);
}, [debounced]);

  const filterDataBySearchInput = (searchInput) => {
    const normalizedSearchInput = searchInput.toLowerCase();
    
    const newData = !searchInput ? originData : originData.filter(
      ({ validator, moniker }) => 
        validator?.toLowerCase().includes(normalizedSearchInput) || 
        moniker?.toLowerCase().includes(normalizedSearchInput)
    );
  
    setData(newData);
    setTableParams((prevTableParams) => ({
      ...prevTableParams,
      pagination: {
        ...prevTableParams.pagination,
        total: newData.length,
      },
    }));
  };

  const handleChange = (e) => {
    const searchValue = e.target.value;
    if (searchValue.startsWith(' ')) {
        return;
    }
    setSearchValue(searchValue);
  };

  return (
    <>
      <Search
        placeholder="Search by validator/moniker"
        enterButton
        style={{ maxWidth: 500, height: 60 }}
        size="large"
        value={searchValue}
        onChange={handleChange}
      />
      <Table
        dataSource={data}
        columns={columns}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </>
  );
}

export default Validator;

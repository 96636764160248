import { Card, Flex, Spin, Table } from 'antd';
import { useEffect, useState } from 'react';
import { fetchBlockDetail } from '~/api/store_api.ts';

import { Link, useParams } from 'react-router-dom';
import config from '~/config';
import { ColumnsType } from 'antd/es/table';
type BlockType = {
  header: {
    height: string;
    proposer_address: string;
    time: string;
  };
  block_id: string;
  tx_hashes: {
    tx_type: string;
    hash_id: string;
  }[];
};
interface DataType {
  tx_type: string;
  hash_id: string;
}

function generateTransDetailPath(template, tx) {
  let path = template;
  path = path.replace(`:tx`, tx);
  return path;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Transaction Type',
    dataIndex: 'tx_type',
    key: 'tx_type',
  },
  {
    title: 'Hash Id',
    dataIndex: 'hash_id',
    key: 'hash_id',
    render: (hash) => <Link to={generateTransDetailPath(config.routes.transactionDetail, hash)}>{hash}</Link>,
  },
];

function BlockDetail() {
  const [loading, setLoading] = useState(false);
  const [blockDetail, setBlockDetail] = useState<BlockType>({
    header: {
      height: '',
      proposer_address: '',
      time: '',
    },
    block_id: '',
    tx_hashes: [{ tx_type: '', hash_id: '' }],
  });
  let { height } = useParams();

  const getBlockDetail = async () => {
    try {
      setLoading(true);
      const response = await fetchBlockDetail(height);
      setBlockDetail(response || {});
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBlockDetail();
  }, [height]);

  function generatePath(template, address) {
    let path = template;
    path = path.replace(`:address`, address);
    return path;
  }

  return (
    <Flex vertical gap="large">
      {loading ? (
        <>
          <div className="spin-wrapper">
            <Spin tip="Loading" size="large" />
          </div>
        </>
      ) : !blockDetail?.block_id ? (
        <>Data not found</>
      ) : (
        <>
          <div className="info-container">
            <div className="info-wrapper hash">
              <p>Hash</p>
              <div className="info">
                <p>{blockDetail?.block_id}</p>
              </div>
            </div>
            <div className="info-wrapper proposer">
              <p>Proposer</p>
              <div className="info ">
                <Link to={generatePath(config.routes.validatorDetail, blockDetail?.header.proposer_address)}>
                  {blockDetail?.header.proposer_address}
                </Link>
              </div>
            </div>
            <div className="info-wrapper">
              <p>Height</p>
              <div className="info">
                <p>{blockDetail?.header.height}</p>
              </div>
            </div>
            <div className="info-wrapper">
              <p>No of Txs</p>
              <div className="info">
                <p>{blockDetail?.tx_hashes.length}</p>
              </div>
            </div>
            <div className="info-wrapper">
              <p>Time</p>
              <div className="info">
                <p>{blockDetail?.header.time}</p>
              </div>
            </div>
          </div>

          <Card title={<h3 style={{ fontWeight: 'bold' }}>TX Hashes</h3>}>
            {loading ? (
              <div className="spin-wrapper">
                <Spin tip="Loading" size="large" />
              </div>
            ) : (
              <Table
                dataSource={blockDetail?.tx_hashes?.map((item, index) => ({ ...item, key: index.toString() }))}
                columns={columns}
                loading={loading}
                pagination={false}
                scroll={{ y: 400 }}
              />
            )}
          </Card>
        </>
      )}
    </Flex>
  );
}

export default BlockDetail;

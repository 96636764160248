import { Card } from 'antd';
import { Flex, Spin } from 'antd/es';
import { UserOutlined, BlockOutlined, FieldTimeOutlined, IdcardOutlined } from '@ant-design/icons';
import { RootState, setValidatorLength } from '~/redux/actions.ts';
import { useDispatch, useSelector } from 'react-redux';
import { format } from "date-fns";
import { fetchValidatorsWithDetails } from '~/api/store_api.ts';
import { useEffect } from 'react';

function Overview() {
  const dispatch = useDispatch();

  const getTotalValidator = async () => {
    try {
      const response = await fetchValidatorsWithDetails();
      if (response) {
        dispatch(setValidatorLength(response.length));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTotalValidator()
  }, [])

  const validatorLength = useSelector((state: RootState) => state.validatorLength);
  const latestBlockInfo = useSelector((state: RootState) => state.latestBlockInfo);

  return (
    <Card title="Namada Explorer Overview">
      <Flex wrap="wrap">
        <Flex gap="small" className="flex-item border-right border-bottom">
          <div className="icon">
            <IdcardOutlined />
          </div>
          <Flex className="oveview-block" justify="center" align="start" vertical>
            <p>CHAIN ID</p>
            <h2>shielded-expedition.88f17d1d14</h2>
          </Flex>
        </Flex>
        <Flex gap="small" className="flex-item border-bottom padding-left">
          <div className="icon">
            <FieldTimeOutlined />
          </div>
          <Flex className="oveview-block" justify="center" align="start" vertical>
            <p>Block Time</p>
            {latestBlockInfo?.timestamp ? <h2>{format(latestBlockInfo?.timestamp, "yyyy-MM-dd hh:mm:ss")}</h2> : <Spin size="large" />}
          </Flex>
        </Flex>
        <Flex gap="small" className="flex-item border-right">
          <div className="icon">
            <BlockOutlined />
          </div>
          <Flex className="oveview-block" justify="center" align="start" vertical>
            <p>Block Height</p>
            {latestBlockInfo?.height ? <h2>{latestBlockInfo?.height}</h2> : <Spin size="large" />}
          </Flex>
        </Flex>
        <Flex gap="small" className="flex-item padding-left">
          <div className="icon">
            <UserOutlined />
          </div>
          <Flex className="oveview-block" justify="center" align="start" vertical>
            <p>Validators Active</p>
            {validatorLength ? <h2>{validatorLength}</h2> : <Spin size="large" />}
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}

export default Overview;

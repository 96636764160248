import './GlobalStyles.scss'

export const tokenTheme = {
  fontFamily: "'Roboto', sans-serif",
  colorPrimary: '#fce14c'
}

function GlobalStyles({children}) {
  return children
}
export default GlobalStyles
import { useEffect, useState } from 'react';
import { Layout, Menu, theme, Breadcrumb, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import logo from '~/assets/namada-icon.svg';
import type { SearchProps } from 'antd/es/input/Search';

const { Header, Content } = Layout;
const { Search } = Input;

const DefaultLayout = ({ children }) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const [breadcrumbItems, setBreadcrumbItems] = useState([{title: 'Home'}]);
  const [currentKey, setCurrentKey] = useState(null);

  const location = useLocation();
  useEffect(() => {
    let breadcrumb = [];
    if (location.pathname === '/') {
      breadcrumb = [{title: 'Home'}];
      setCurrentKey(['1']);
    } else if (location.pathname === '/validators') {
      breadcrumb = [{title: 'Validators'}];
      setCurrentKey(['2']);
    } else if (location.pathname.startsWith('/validator/')) {
      breadcrumb = [{title: 'Validators'}, {title: 'Detail'}];
      setCurrentKey(['2']);
    } else if (location.pathname === '/blocks') {
      breadcrumb = [{title: 'Blocks'}];
      setCurrentKey(['3']);
    } else if (location.pathname.startsWith('/block/')) {
      breadcrumb = [{title: 'Blocks'}, {title: 'Detail'}];
      setCurrentKey(['3']);
    } else if (location.pathname === '/transactions') {
      breadcrumb = [{title: 'Transactions'}];
      setCurrentKey(['4']);
    } else if (location.pathname.startsWith('/transaction/')) {
      breadcrumb = [{title: 'Transactions'}, {title: 'Detail'}];
      setCurrentKey(['4']);
    } else if (location.pathname === '/governances') {
      breadcrumb = [{title: 'Governances'}];
      setCurrentKey(['5']);
    }
    setBreadcrumbItems(breadcrumb);
  }, [location.pathname]);

  const handleMenuClick = (e) => {
    let breadcrumb = [];
    switch (e.key) {
      case '1':
        navigate('/');
        breadcrumb = [{title: 'Home'}];
        setCurrentKey(['1']);
        break;
      case '2':
        navigate('/validators');
        breadcrumb = [{title: 'Validators'}];
        setCurrentKey(['2']);
        break;
      case '3':
        navigate('/blocks');
        breadcrumb = [{title: 'Blocks'}];
        setCurrentKey(['3']);
        break;
      case '4':
        navigate('/transactions');
        breadcrumb = [{title: 'Transactions'}];
        setCurrentKey(['4']);
        break;
      case '5':
        navigate('/governances');
        breadcrumb = [{title: 'Governances'}];
        setCurrentKey(['5']);
        break;
      default:
        breadcrumb = ['Home'];
        setCurrentKey(['1']);
        break;
    }
    setBreadcrumbItems(breadcrumb);
  };

  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    const searchData = value.trim();
    if (searchData.length > 0) {
      if (searchData.length === 64) {
        navigate(`/transaction/${searchData}`);
      } else if (searchData.length === 40) {
        navigate(`/validator/${searchData}`);
      } else {
        navigate(`/block/${searchData}`);
      }
    }
  };

  const items = [
    {
      key: '1',
      label: 'Home',
    },
    {
      key: '2',
      label: 'Validators',
    },
    {
      key: '3',
      label: 'Blocks',
    },
    {
      key: '4',
      label: 'Transactions',
    },
    {
      key: '5',
      label: 'Governances',
    },
  ];

  return (
    <Layout style={{height: '100vh'}}>
      <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyItems: 'end',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px 0' }}>
          <img
            src={logo}
            height={50}
            alt="logo"
            style={{
              marginRight: '10px',
            }}
          ></img>
        </div>
        <Search
          placeholder="height/ tx/ validator "
          enterButton
          style={{ maxWidth: 600, marginLeft: 50 }}
          size="large"
          onSearch={onSearch}
          allowClear
        />
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={currentKey}
          style={{ flex: 1, maxWidth: 500, display: 'flex', justifyContent: 'end' }}
          items={items}
          onClick={handleMenuClick}
        />
      </Header>
      <Content style={{ padding: '0 48px' }}>
      <Breadcrumb
          style={{ margin: '1.5rem' }}
          items={breadcrumbItems}
        />
        <div
          style={{
            padding: 24,
            minHeight: 380,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          {children}
        </div>
      </Content>
    </Layout>
  );
};

export default DefaultLayout;

import { GetProp, Input, Table, TableProps } from 'antd';
import { useEffect, useState } from 'react';
import { fetchProposals } from '~/api/store_api.ts';
import { useDebounce } from '~/hooks';

type ColumnsType<T> = TableProps<T>['columns'];
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface DataType {
  id: string;
  type: string;
  author: string;
  result: string;
  startEpoch: string;
  endEpoch: string;
  graceEpoch: string;
  yesVotes: string;
  noVotes: string;
  abstainVotes: string;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

const { Search } = Input;

const columns: ColumnsType<DataType> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'TYPE',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'AUTHOR',
    dataIndex: 'author',
    key: 'author',
  },
  {
    title: 'RESULT',
    dataIndex: 'result',
    key: 'result',
    render: (result) => <p className={result === 'Passed' ? 'success' : result === 'Rejected' ? 'failed' : 'pending'}>{result}</p>,
  },
  {
    title: 'START EPOCH',
    dataIndex: 'startEpoch',
    key: 'startEpoch',
  },
  {
    title: 'END EPOCH',
    dataIndex: 'endEpoch',
    key: 'endEpoch',
  },
  {
    title: 'GRACE EPOCH',
    dataIndex: 'graceEpoch',
    key: 'graceEpoch',
  },
  {
    title: 'YAY VOTES (YES)',
    dataIndex: 'yesVotes',
    key: 'yesVotes',
  },
  {
    title: 'NAY VOTES (NO)',
    dataIndex: 'noVotes',
    key: 'noVotes',
  },
  {
    title: 'ABSTAIN VOTES (ABSTAIN)',
    dataIndex: 'abstainVotes',
    key: 'abstainVotes',
  },
];

function Proposals() {
  const [data, setData] = useState<DataType[]>([]);
  const [originData, setOriginData] = useState<DataType[]>([]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [searchValue, setSearchValue] = useState('');
  const debounced = useDebounce(searchValue, 500);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetchProposals();
      if (response) {
        const tempData = response.map((data, index) => {
          return {
            key: index,
            ...data,
          };
        });
        setOriginData(tempData);
        setData(tempData);

        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: tempData.length,
          },
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter, extra) => {
    setTableParams({
      pagination,
    });
  };

  useEffect(() => {
    filterDataBySearchInput(debounced);
}, [debounced]);

  const filterDataBySearchInput = (searchInput) => {
    const normalizedSearchInput = searchInput.toLowerCase();
    
    const newData = !searchInput ? originData : originData.filter(
      ({ author }) => 
        author?.toLowerCase().includes(normalizedSearchInput)
    );
  
    setData(newData);
    setTableParams((prevTableParams) => ({
      ...prevTableParams,
      pagination: {
        ...prevTableParams.pagination,
        total: newData.length,
      },
    }));
  };

  const handleChange = (e) => {
    const searchValue = e.target.value;
    if (searchValue.startsWith(' ')) {
        return;
    }
    setSearchValue(searchValue);
  };

  return (
    <>
      <Search
        placeholder="Search by author"
        enterButton
        style={{ maxWidth: 500, height: 60 }}
        size="large"
        value={searchValue}
        onChange={handleChange}
      />
      <Table
        dataSource={data}
        columns={columns}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </>
  );
}

export default Proposals;

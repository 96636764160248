import { GetProp, Table, TableProps } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchBlocks } from '~/api/store_api.ts';
import config from '~/config';
import { format, formatDistanceToNow } from 'date-fns';
import useInterval from '~/hooks/useInterval';
import { Card, Tooltip } from 'antd/es';
import { useDispatch } from 'react-redux';
import { setLatestBlockInfo } from '~/redux/actions.ts';

type ColumnsType<T> = TableProps<T>['columns'];
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface DataType {
  height: string;
  hash: string;
  txs: string;
  proposer: string;
  time: string;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

function generateBlockDetailPath(template, height) {
  let path = template;
  path = path.replace(`:height`, height);
  return path;
}

function generateValidatorDetailPath(template, address) {
  let path = template;
  path = path.replace(`:address`, address);
  return path;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'NO',
    dataIndex: 'no',
    key: 'no',
  },
  {
    title: 'BLOCK',
    dataIndex: 'height',
    key: 'height',
    render: (height) => <Link to={generateBlockDetailPath(config.routes.blockDetail, height)}>{height}</Link>,
  },
  {
    title: 'Total Transactions',
    dataIndex: 'txs',
    key: 'txs',
  },
  {
    title: 'HASH',
    dataIndex: 'hash',
    key: 'hash',
  },
  {
    title: 'VALIDAROR',
    dataIndex: 'proposer',
    key: 'proposer',
    render: (proposer) => (
      <Link to={generateValidatorDetailPath(config.routes.validatorDetail, proposer)}>{proposer}</Link>
    ),
  },
  {
    title: 'TIME',
    dataIndex: 'time',
    key: 'time',
    render: (time) => (
      <Tooltip placement="bottom" title={format(time, 'yyyy-MM-dd hh:mm:ss')}>
        <p>{formatDistanceToNow(time || '', { addSuffix: true })}</p>
      </Tooltip>
    ),
  },
];

function LastestBlocks() {
  const [originData, setOriginData] = useState<DataType[]>([]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const dispatch = useDispatch();

  var isFetchingData = false;
  const fetchData = async () => {
    try {
      if (isFetchingData) {
        return;
      }

      isFetchingData = true;
      const response = await fetchBlocks(1, 10);
      if (response && response.data) {
        dispatch(
          setLatestBlockInfo({
            height: response.data[0].height,
            timestamp: response.data[0].time,
          }),
        );
        const tempData = response.data.map((data) => {
          return {
            key: data.height,
            ...data,
          };
        });

        if (originData.length === 0) {
          setOriginData(tempData);

          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              total: tempData.length,
            },
          });
        } else {
          setOriginData(tempData);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      isFetchingData = false;
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
  }, []);

  useInterval(() => {
    fetchData();
  }, 5000);

  return (
    <>
      <Card title="Latest 10 Blocks" style={{ marginTop: '2rem' }}>
        <Table dataSource={originData} columns={columns} pagination={tableParams.pagination} loading={loading} />
      </Card>
    </>
  );
}

export default LastestBlocks;

export interface RootState {
  validatorLength: number;
  latestBlockInfo: LatestBlockInfo;
}

export interface LatestBlockInfo {
  height: string;
  timestamp: string;
}

export const setValidatorLength = (length: number) => ({
  type: 'SET_VALIDATOR_LENGTH',
  payload: length,
});

export const setLatestBlockInfo = (info: LatestBlockInfo) => {
  return {
    type: 'SET_LATEST_BLOCK_INFO',
    payload: info,
  }
};
import LastestBlocks from "./component/LatestBlocks.tsx";
import LatestTransactions from "./component/LatestTransactions.tsx";
import Overview from "./component/Overview.tsx";


function Home() {
  return (
    <>
      <Overview/>
      <LatestTransactions />
      <LastestBlocks />
    </>
  );
}

export default Home;
